body {
  // background: #000;
  font-family: "Gilroy-SemiBold", sans-serif;
  background: #1e2835;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.homeCardBox {
  border-radius: 10px;
  background: #233042;
  border: 1px solid #233042;
  padding: 12px;
  cursor: pointer;
  p {
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
  }
  h6 {
    font-weight: 400;
    font-size: 12px !important;
  }
}
.homeCardBox:hover {
  border: 1px solid #3498db2e;
  background: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0.06) 13.67%,
      rgba(255, 255, 255, 0) 29.26%,
      rgba(255, 255, 255, 0.1) 43.15%,
      rgba(255, 255, 255, 0) 58.14%,
      rgba(255, 255, 255, 0.06) 72.74%,
      rgba(255, 255, 255, 0) 84.46%,
      rgba(255, 255, 255, 0.1) 97.23%
    ),
    #233042;
}
.displayStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.displaySpacebetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.displayColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.buttonBoxback {
  position: absolute !important;
  left: 0 !important;
  background: transparent !important;
  top: 23px;
  color: #fff !important;
}
.walletButton {
  background: transparent !important;
  padding: 0px !important;
  width: auto !important;
  height: auto !important;
}
.displayCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.noticeButton {
  border-radius: 50px !important;
  background: linear-gradient(
    131deg,
    #69bffe 29.63%,
    #366eff 84.44%
  ) !important;
  color: #fff !important;
  border: 1px solid #5caaff !important;
  padding: 10px 30px !important;
  height: 43px !important;
}

.borderbottom {
  border-bottom: 1px solid #4c5d75;
  padding-bottom: 13px;
}
.buttonBoxback1 {
  top: 0px !important;
}
.buttonBox button {
  color: #fff !important;
}
button {
  white-space: pre !important;
  height: 46px !important;
  font-weight: 500 !important;

  text-align: center !important;
}
.iekbcc0.ju367va.ju367vz {
  max-height: 100% !important;
}
